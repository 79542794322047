<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">关键字</div>
					<div class="inputs_box">
						<el-input clearable type="text" placeholder="请输入名称" v-model="forms.keyword" />
					</div>
				</div>

				<div class="search_flex">
					<div class="texts">部门</div>
					<div class="inputs_box">
						<bumen_cascader ref="bumen_cascader" :is_show_position="false" @confirm="confirm_bumen_cascader_value" />
					</div>
				</div>

				<div class="search_flex">
					<div class="texts">时间范围</div>
					<div class="inputs_box">
						<!--                        <el-date-picker :editable="false" 
                        value-format="yyyy-MM-dd" :default-time="['00:00:00','23:59:59']" v-model="times" type="daterange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker> -->
						<!-- 	<el-date-picker v-model="month_select" value-format="yyyy-MM-dd" type="month" placeholder="选择月" :picker-options="pickerOptions">
						</el-date-picker> -->
						<!-- value-format="yyyy-MM-dd" -->
						<el-date-picker v-model="year_select" value-format="yyyy" type="year" placeholder="年份"
							:picker-options="pickerOptions">
						</el-date-picker>
					</div>
				</div>
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search">搜索</div>
				</div>
			</div>

		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
					<span>(社保修改后，当月及以后工资会按修改后的数值计算)</span>
				</div>
				<div class="rights">
					<div class="btns" @click="do_check_history()">
						查看历史减员记录
					</div>
					<div class="btns" @click="do_add()">
						社保增员
					</div>
					<div class="btns" @click="do_delete()">
						社保减员
					</div>
					<div class="btns sb_el_btns" >
						<el-dropdown  @command="do_import"  trigger="click">
							<div class="el-dropdown-link">
								社保增员批量导入
							</div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(item,index) in btn_list" :key="index" :command="item">
									<div class="btn_text_dr_sb">{{ item.title }}</div>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- 暂时无需 -->
					<el-table-column fixed type="selection" width="55" label="序号">

					</el-table-column>
					<el-table-column fixed prop="name" label="姓名" width=""></el-table-column>
					<el-table-column prop="idType" label="证件类型" width=""></el-table-column>
					<el-table-column prop="idNo" label="证件号码" width="200"></el-table-column>
					<el-table-column prop="socNo" label="人员编号" width="150"></el-table-column>
					<el-table-column prop="jc_jishu" label="缴存基数" width=""></el-table-column>
					<el-table-column prop="jc_money" label="缴额" width=""></el-table-column>
					<el-table-column prop="jc_company" label="单位缴纳" width=""></el-table-column>
					<el-table-column prop="jc_user" label="个人缴纳" width=""></el-table-column>
					<el-table-column prop="social10" label="养老保险(单位缴纳)" width="">
						<el-table-column prop="social10.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social10.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social10.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social10.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>

					<el-table-column prop="social11" label="养老保险(个人缴纳)" width="">
						<el-table-column prop="social11.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social11.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social11.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social11.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>

					<el-table-column prop="social20" label="医疗保险(单位缴纳)" width="">
						<el-table-column prop="social20.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social20.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social20.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social20.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="social21" label="医疗保险(个人缴纳)" width="">
						<el-table-column prop="social21.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social21.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social21.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social21.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>

					<el-table-column prop="social30" label="工伤保险(单位缴纳)" width="">
						<el-table-column prop="social30.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social30.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social30.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social30.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="social31" label="工伤保险(个人缴纳)" width="">
						<el-table-column prop="social31.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social31.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social31.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social31.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="social40" label="失业保险(单位缴纳)" width="">
						<el-table-column prop="social40.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social40.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social40.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social40.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="social41" label="失业保险(个人缴纳)" width="">
						<el-table-column prop="social41.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social41.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social41.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social41.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="social50" label="大额医疗(单位缴纳)" width="">
						<el-table-column prop="social50.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social50.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social50.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social50.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="social51" label="公积金(单位缴纳)" width="">
						<el-table-column prop="social51.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social51.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social51.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social51.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="social60" label="公积金(单位缴纳)" width="">
						<el-table-column prop="social60.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social60.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social60.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social60.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="social61" label="企业年金(单位缴纳)" width="">
						<el-table-column prop="social61.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social61.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social61.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social61.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="social70" label="企业年金(个人缴纳)" width="">
						<el-table-column prop="social70.fee" label="费率" width=""></el-table-column>
						<el-table-column prop="social70.payable" label="应缴费额" width=""></el-table-column>
						<el-table-column prop="social70.reduction" label="减免费额" width=""></el-table-column>
						<el-table-column prop="social70.refund" label="应补(退)费额" width="120"></el-table-column>
					</el-table-column>

					<el-table-column prop="created_at" label="商业意外险" width="120">
						<template slot-scope="scope">
							<div class="">{{ scope.row.syyw_insure ==1?'是':'否'}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="created_at" label="安全生产责任险" width="120">
						<template slot-scope="scope">
							<div class="">{{ scope.row.aqzr_insure==1?'是':'否' }}</div>
						</template>
					</el-table-column>


					<!-- 操作模块   -->
					<el-table-column fixed="right" label="操作" width="140">
						<template slot-scope="scope">
							<template v-if="is_show_table_actions">
								<el-button type="text" size="small" class="btn_modify"
									@click="handle_btn_modify(scope.row)">编辑</el-button>
								<el-button type="text" size="small" class="btn_delete"
									@click="handle_btn_delete(scope.row)">减员</el-button>
							</template>
							<template v-else>
								-
							</template>
						
					
						</template>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
		<up_files ref="up_files" @handle_up_end="handle_up_end"></up_files>

		<!--  -->
		<shebao_user_add_modal data-title="社保增员" ref="shebao_user_add_modal" @handle_get_list="handle_get_list" />
		<shebao_user_delete_modal data-title="社保减员" ref="shebao_user_delete_modal" @handle_get_list="handle_get_list" />

	</div>
</template>
<script>
	import dayjs from 'dayjs'
	
	import page from "@/components/page/page.vue"; //分页  统一的
	import shebao_user_add_modal from "@/components/shebao/shebao_user_add_modal.vue"; //增员
	import shebao_user_delete_modal from "@/components/shebao/shebao_user_delete_modal.vue"; //减员

	export default {
		components: {
			page,
			shebao_user_add_modal,
			shebao_user_delete_modal,
		},
		data() {
			return {
				bumen_position_info: {
					
				},
				//
				month_select: '',
				is_show_table_actions: true,
				year_current: dayjs().format('YYYY'),
				year_select: dayjs().format('YYYY'),
			 
				pickerOptions: {
					disabledDate(time) {
						console.log('time', time)
						return (time.getYear()) > new Date().getYear();
					}
				},
				//
				//
				//
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '',
				},
				list: [{
						name: '123123'
					},

				],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围
				btn_list:[
				 {
					title:'养老保险(单位缴纳)',
					value:'social10',
					
				 },
				 {
					title:'养老保险(个人缴纳)',
					value:'social11',
					 
				 },
				 {
					title:'医疗保险(单位缴纳)',
					value:'social20',
					 
				 },
				 {
					title:'医疗保险(个人缴纳)',
					value:'social21',
					 
				 }, 
				 {
					title:'工伤保险(单位缴纳)',
					value:'social30',
					 
				 },
				 {
					title:'工伤保险(个人缴纳)',
					value:'social31',
					 
				 }, {
					title:'失业保险(单位缴纳)',
					value:'social40',
					 
				 },
				 {
					title:'失业保险(个人缴纳)',
					value:'social41',
					 
				 }, {
					title:'大额医疗(单位缴纳)',
					value:'social50',
					 
				 },
				 {
					title:'大额医疗(个人缴纳)',
					value:'social51',
					 
				 }, {
					title:'公积金(单位缴纳)',
					value:'social60',
					 
				 },
				 {
					title:'公积金(个人缴纳)',
					value:'social61',
					 
				 }, {
					title:'企业年金(单位缴纳)',
					value:'social71',
					 
				 },
				 {
					title:'企业年金(个人缴纳)',
					value:'social71',
					 
				 },

				//  10:'养老保险(单位缴纳)',
				// 	11:'养老保险(个人缴纳)',
				// 	20:'医疗保险(单位缴纳)',
				// 	21:'医疗保险(个人缴纳)',
				// 	30:'工伤保险(单位缴纳)',
				// 	31:'工伤保险(个人缴纳)',
				// 	40:'失业保险(单位缴纳)',
				// 	41:'失业保险(个人缴纳)',
				// 	50:'大额医疗(单位缴纳)',
				// 	51:'大额医疗(个人缴纳)',
				// 	60:'公积金(单位缴纳)',
				// 	61:'公积金(个人缴纳)',
				// 	70:'企业年金(单位缴纳)',
				// 	71:'企业年金(个人缴纳)',
				],
				btn_ac_obj:{},//点击的按钮参数
				
			};
		},
		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
		},
		mounted() {

		},
		methods: {
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				 

				console.log(this.forms)
				this.loading = true
				this.$api("socialEmpList", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
					year: this.year_select,
					...this.bumen_position_info
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
					} else {
						alertErr(res.msg)
					}
					
					//当年数据允许编辑
					this.is_show_table_actions  = this.year_current == this.year_select
					
				});
			},


			 


			//编辑
			handle_btn_modify(row) {
				this.$refs.shebao_user_add_modal.init(row)
			},
			//减员
			handle_btn_delete(row) {
				this.$refs.shebao_user_delete_modal.init(row)
			},

			//查看历史减员记录
			do_check_history() {
				this.$router.push('/shebao_delete_history')
			},
			//社保增员
			do_add() {
				this.$refs.shebao_user_add_modal.init()
			},
			//社保减员
			do_delete() {
				this.$refs.shebao_user_delete_modal.init()
			},
			//社保增员批量导入
			do_import(e) {
				console.log(e)
				this.btn_ac_obj=e
				let obj={
					file_url:'http://jinniu.dx.hdapp.com.cn/dr_file/模板文件社保.xlsx',
				}
				this.$refs.up_files.handle_open(obj)
				// this.handle_up_end()

			},
			handle_up_end(info){
				console.log(info)
				
				this.$api("socialImportXls", {
					file: info.path,
					type: this.btn_ac_obj.value
				}, "post").then((res) => {
					alert(res)
					if (res.code == 200) {
						this.get_list()
					}
				});

			},


			confirm_bumen_cascader_value(value) {
				this.$log('勾选部门数据', value)
			let departIds = '';

				if (typeof value[0] == 'object') {

					let last_id_arr = value.map(arr => arr[arr.length - 1])

					departIds = last_id_arr.join(',')

				} else {

					departIds = value[value.length - 1];

				}

				let departItems = JSON.stringify(value)

				this.$log('departIds', departIds)
				this.$log('departItems', departItems)

				this.bumen_position_info = {
					position: departIds
				}
			},
		},
	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						margin-left: 20px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
						.el-dropdown-link{
							color: #FFFFFF;
						}
						
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>